import { CCLogger } from '../../dashboard/helper/logger';

export const initOnEvents = ['click', 'touchstart', 'keypress', 'keydown'];

export const getAudioContext = () => {
  let audioCtx;
  try {
    audioCtx = new (window.AudioContext || window.webkitAudioContext)();
  } catch {
    // AudioContext is not available.
  }
  return audioCtx;
};

export const setupAudioAlert = async (baseUrl = '', requestContext) => {
  CCLogger.frontendEvents('setup audio alert ->', requestContext);

  window.playContactCenterAudioAlerts = () => {
    const { type = 'dashboard', alertTone } = requestContext || {};
    CCLogger.frontendEvents('play audio alert ->', requestContext);
    const resourceUrl = `${baseUrl}/audio/${type}/${alertTone}.mp3`;
    const audio = new Audio(resourceUrl);
    audio.play();
  };
};
