<template>
  <div class="flex-1 overflow-auto p-4">
    <div class="items-center flex gap-3">
      <span class="text-slate-800 dark:text-slate-100 text-xs font-medium">{{
        this.$t('CHAT_LIST.CHAT_SORT.ORDER_BY')
      }}</span>
      <select
        v-model="activeSort"
        class="bg-slate-25 dark:bg-slate-700 text-xs h-6 my-0 mx-1 py-0 pr-6 pl-2 w-32 border border-solid border-slate-75 dark:border-slate-600 text-slate-800 dark:text-slate-100 capitalize"
      >
        <option v-for="value in chatSortItems" :key="value" :value="value">
          {{ value }}
        </option>
      </select>
      <select
        v-model="activeSortOrder"
        class="bg-slate-25 dark:bg-slate-700 text-xs h-6 my-0 mx-1 py-0 pr-6 pl-2 w-32 border border-solid border-slate-75 dark:border-slate-600 text-slate-800 dark:text-slate-100 capitalize"
      >
        <option
          v-for="value in activeSortOrderItems"
          :key="value"
          :value="value"
        >
          {{ value }}
        </option>
      </select>
    </div>
    <!-- <woot-button
      color-scheme="success"
      class-names="button--fixed-top"
      icon="add-circle"
      @click="openAddPopup()"
    >
      {{ $t('AGENT_MGMT.HEADER_BTN_TXT') }}
    </woot-button> -->

    <!-- List Agents -->
    <div class="flex flex-row gap-4">
      <div class="w-[60%]">
        <woot-loading-state
          v-if="uiFlags.isFetching"
          :message="$t('AGENT_MGMT.LOADING')"
        />
        <div v-else>
          <p v-if="!agentList.length">
            {{ $t('AGENT_MGMT.LIST.404') }}
          </p>
          <table v-else class="woot-table">
            <tbody>
              <tr v-for="agent in sortedAgentList" :key="agent.email">
                <!-- Gravtar Image -->
                <td>
                  <thumbnail
                    :src="agent.thumbnail"
                    class="columns ph-no-capture"
                    :username="agent.name"
                    size="40px"
                    :status="agent.availability_status"
                  />
                </td>
                <!-- Agent Name + Email -->
                <td>
                  <span class="agent-name ph-no-capture">
                    {{ agent.name }}
                  </span>
                  <span class="ph-no-capture">{{ agent.email }}</span>
                </td>
                <!-- Agent Role + Verification Status -->
                <td>
                  <span class="agent-name ph-no-capture">
                    {{
                      $t(`AGENT_MGMT.AGENT_TYPES.${agent.role.toUpperCase()}`)
                    }}
                  </span>
                  <span v-if="agent.confirmed">
                    {{ $t('AGENT_MGMT.LIST.VERIFIED') }}
                  </span>
                  <span v-if="!agent.confirmed">
                    {{ $t('AGENT_MGMT.LIST.VERIFICATION_PENDING') }}
                  </span>
                </td>
                <!-- Actions -->
                <td>
                  <div class="button-wrapper agent-table-buttons">
                    <woot-button
                      v-if="showEditAction(agent)"
                      v-tooltip.top="$t('AGENT_MGMT.EDIT.BUTTON_TEXT')"
                      variant="smooth"
                      size="tiny"
                      color-scheme="secondary"
                      icon="edit"
                      class-names="grey-btn"
                      @click="openEditPopup(agent)"
                    />
                    <!-- <woot-button
                      v-if="showDeleteAction(agent)"
                      v-tooltip.top="$t('AGENT_MGMT.DELETE.BUTTON_TEXT')"
                      variant="smooth"
                      color-scheme="alert"
                      size="tiny"
                      icon="dismiss-circle"
                      class-names="grey-btn"
                      :is-loading="loading[agent.id]"
                      @click="openDeletePopup(agent, index)"
                    /> -->
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="w-[34%]">
        <span
          v-dompurify-html="
            useInstallationName(
              $t('AGENT_MGMT.SIDEBAR_TXT'),
              globalConfig.installationName
            )
          "
        />
      </div>
    </div>
    <!-- Add Agent -->
    <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
      <add-agent :on-close="hideAddPopup" />
    </woot-modal>
    <!-- Edit Agent -->
    <woot-modal :show.sync="showEditPopup" :on-close="hideEditPopup">
      <edit-agent
        v-if="showEditPopup"
        :id="currentAgent.id"
        :name="currentAgent.name"
        :type="currentAgent.role"
        :email="currentAgent.email"
        :availability="currentAgent.availability_status"
        :on-close="hideEditPopup"
      />
    </woot-modal>
    <!-- Delete Agent -->
    <woot-delete-modal
      :show.sync="showDeletePopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('AGENT_MGMT.DELETE.CONFIRM.TITLE')"
      :message="$t('AGENT_MGMT.DELETE.CONFIRM.MESSAGE')"
      :message-value="deleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import Thumbnail from '../../../../components/widgets/Thumbnail';
import AddAgent from './AddAgent';
import EditAgent from './EditAgent';

export default {
  components: {
    AddAgent,
    EditAgent,
    Thumbnail,
  },
  mixins: [globalConfigMixin],
  data() {
    return {
      loading: {},
      showAddPopup: false,
      showDeletePopup: false,
      showEditPopup: false,
      agentAPI: {
        message: '',
      },
      currentAgent: {},
      activeSort: 'name',
      chatSortItems: ['name', 'status'],
      activeSortOrder: 'asc',
    };
  },
  computed: {
    ...mapGetters({
      agentList: 'agents/getAgents',
      uiFlags: 'agents/getUIFlags',
      currentUserId: 'getCurrentUserID',
      globalConfig: 'globalConfig/get',
    }),
    deleteConfirmText() {
      return `${this.$t('AGENT_MGMT.DELETE.CONFIRM.YES')} ${
        this.currentAgent.name
      }`;
    },
    deleteRejectText() {
      return `${this.$t('AGENT_MGMT.DELETE.CONFIRM.NO')} ${
        this.currentAgent.name
      }`;
    },
    deleteMessage() {
      return ` ${this.currentAgent.name}?`;
    },
    activeSortOrderItems() {
      return this.activeSort === 'name'
        ? ['asc', 'desc']
        : ['online', 'offline', 'busy'];
    },
    sortedAgentList() {
      return this.activeSort === 'name'
        ? this.sortByName(this.agentList, this.activeSortOrder)
        : this.sortByStatus(this.agentList, this.activeSortOrder);
    },
  },
  watch: {
    activeSort(newVal) {
      if (newVal === 'status') {
        this.activeSortOrder = 'online';
      } else {
        this.activeSortOrder = 'asc';
      }
    },
  },
  mounted() {
    this.$store.dispatch('agents/get');
  },
  methods: {
    showEditAction(agent) {
      return this.currentUserId !== agent.id;
    },
    showDeleteAction(agent) {
      if (this.currentUserId === agent.id) {
        return false;
      }

      if (!agent.confirmed) {
        return true;
      }

      if (agent.role === 'administrator') {
        return this.verifiedAdministrators().length !== 1;
      }
      return true;
    },
    verifiedAdministrators() {
      return this.agentList.filter(
        agent => agent.role === 'administrator' && agent.confirmed
      );
    },
    // Edit Function
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },

    // Edit Function
    openEditPopup(agent) {
      this.showEditPopup = true;
      this.currentAgent = agent;
    },
    hideEditPopup() {
      this.showEditPopup = false;
    },

    // Delete Function
    openDeletePopup(agent) {
      this.showDeletePopup = true;
      this.currentAgent = agent;
    },
    closeDeletePopup() {
      this.showDeletePopup = false;
    },
    confirmDeletion() {
      this.loading[this.currentAgent.id] = true;
      this.closeDeletePopup();
      this.deleteAgent(this.currentAgent.id);
    },
    async deleteAgent(id) {
      try {
        await this.$store.dispatch('agents/delete', id);
        this.showAlert(this.$t('AGENT_MGMT.DELETE.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('AGENT_MGMT.DELETE.API.ERROR_MESSAGE'));
      }
    },
    // Show SnackBar
    showAlert(message) {
      // Reset loading, current selected agent
      this.loading[this.currentAgent.id] = false;
      this.currentAgent = {};
      // Show message
      this.agentAPI.message = message;
      bus.$emit('newToastMessage', message);
    },
    sortByName(array, order = 'asc') {
      return array.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (order === 'asc') {
          return nameA.localeCompare(nameB);
        }
        return nameB.localeCompare(nameA);
      });
    },
    sortByStatus(array, selectedStatus) {
      return array.sort((a, b) => {
        if (
          a.availability === selectedStatus &&
          b.availability !== selectedStatus
        ) {
          return -1;
        }
        if (
          a.availability !== selectedStatus &&
          b.availability === selectedStatus
        ) {
          return 1;
        }
        return 0;
      });
    },
  },
};
</script>
